// import ReactGA from "react-ga";

import init from "./init";
import logEvent from './logEvent'
import type { LoggingEvent } from './logEvent'


/**
 * Logs a user following a link to location within the site
 * * Only run on clientside
 * @param location A label that decribes the link for easy reference
 * @param to The location the user is being sent to
 */

const internalLinkClick = (location: string, to: string, id: string | undefined, portfolio_id: string | undefined) => {
  let from = typeof window !== undefined ? window.location.pathname + window.location.search : "Unkown";
  // UA
  init();

  let layerUpdate : LoggingEvent = { event: "link_click", internal_external: "internal", from };

  if (location) layerUpdate.location = location;
  if (to) layerUpdate.to = to;
  if (id) layerUpdate.result_id = id
  if (portfolio_id) layerUpdate.portfolio_id = portfolio_id

  logEvent(layerUpdate);
};

export default internalLinkClick;
