import type { Modal } from "./_types";
import logEvent from './logEvent'
import type { LoggingEvent } from './logEvent'

/**
 *
 * @param props This object contains
 */

const modal = ({ type, view, visible }: Modal) => {
  let layerUpdate: LoggingEvent = { event: "modal" };

  if (type) layerUpdate.type = type;
  if (view) layerUpdate.view = view;
  if (visible) layerUpdate.visible = visible;

  logEvent(layerUpdate)

};

export default modal;
