import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _THEME from "config/app.theme";
const initialState = {
  site: _THEME,
  portfolio: {
    main: null,
    dark: null,
    iconStyle: {
      backgroundColor: null,
      backgroundImage: null,
    },
    textStyle: {
      color: null,
    },
  },
  page: {},
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {},
});

export default themeSlice.reducer;
