// import ReactGA from "react-ga";

import init from "./init";
import logEvent from './logEvent'

/**
 * * Only run on clientside
 * ! This is a fallback for UA, create specific functions for events going forward
 * @param miscEvent The full event object that will be sent
 */

const miscEvent = (miscEvent: object) => {
  init();

  // TODO: Trace back this event
  // ReactGA.event(miscEvent);
  logEvent({event: 'misc', ...miscEvent})
};

export default miscEvent;
