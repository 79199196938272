import { createAsyncThunk, createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import NextRouter from "next/navigation";

import { modals as modalsConfig } from "config/app.config";
import type { RootState } from "lib/store";
import logModal from "lib/ga/modal";

// Types
// ========================================
interface Modal {
  type?: string;
  view?: string;
  visible?: boolean;
  router: any
}

const checkModalConditions = (state: RootState, type: string, view?: string, isLoggedIn?: boolean) => {
  let showNextModal = true;
  let conditions = {
    ...(modalsConfig?.[type]?.conditions || {}),
    ...(modalsConfig?.[type]?.views?.[view]?.conditions || {}),
  };

  for (const condition in conditions) {
    switch (condition) {
      case "trust":
        showNextModal = state.user.trust >= conditions[condition];
      case "loggedIn":
        console.log(isLoggedIn)
        if (isLoggedIn) {
          showNextModal = isLoggedIn;
        } else {
          showNextModal = conditions[condition] === state.auth.loggedIn;
        }
        
        break;
    }
  }

  return showNextModal;
};

const areModalsDifferent = (oldModal: Modal, newModal: Modal) => {
  if (oldModal.type !== newModal.type) return true;
  if (oldModal.view !== newModal.view) return true;
  if (oldModal.visible !== newModal.visible) return true;
  return false;
};

// State
// ========================================
const initialState = {
  // Full screen modal for things like auth and contact forms
  modal: {
    visible: false,
    type: "",
    view: "",
  },
  // Allows for queuing modals in order to do things like show login
  //   and then contact a rep oncce authenticated.
  //   "visible" prop must be set to true to work
  nextModal: {
    visible: false,
    type: "",
    view: "",
    conditions: [],
  },
};

const queryStringToObject = url =>
  [...new URLSearchParams(url.split('?')[1])].reduce(
    (a, [k, v]) => ((a[k] = v), a),
    {}
  );

// Actions
// ========================================

// Thunks
// ========================================
// We want to setup the modal settings as search parameters
// Also when a modal is set to not be visible we chack if there is a valid next modal
export const modalSet = createAsyncThunk("modalSet", async (newModalSettings: Modal, { getState }) => {
  let query = queryStringToObject(window.location.search);
  const state = getState() as RootState;
  let modal = {
    ...state.view.modal,
    ...newModalSettings,
  };
  let router = modal.router;
  let nextModal = (getState() as RootState).view.nextModal;
  let updateLocation = false;
  if (modal.visible && checkModalConditions(state, modal.type, modal.view, modal.isLoggedIn)) {
    // The modal should be visible
    if (!window.location.search.includes(`${modal.type}-${modal.view}`)) {
      // The modal is not relected in the current url so update it
      updateLocation = true;
      query.modal = `${modal.type}-${modal.view}`;
    }
  } else {
    if (nextModal.visible && checkModalConditions(state, nextModal.type, nextModal.view, modal.isLoggedIn)) {
      // There is another modal that should be shown so update the url
      updateLocation = true;
      modal = {
        visible: nextModal.visible,
        type: nextModal.type,
        view: nextModal.view,
      };
      nextModal = initialState.nextModal;
      query.modal = `${modal.type}-${modal.view}`;
      if (query.nextModal) delete query.nextModal;
    } else {
      nextModal = initialState.nextModal;
      if (window.location.search.includes(`modal=`)) {
        // There is a modal showing that should not be so remove it from the url
        updateLocation = true;
        if (query.modal) delete query.modal;
        if (query.nextModal) delete query.nextModal;
      }
    }
  }
  const newQuery = new URLSearchParams(query).toString()
  if (updateLocation) {
    router.push(
      `${window.location.pathname}?${newQuery}`
    )
   
  }
  logModal({ type: modal.type, view: modal.view });
  return { modal, nextModal, query, asPath: newQuery };
});

// We want to handle the next modal in a similar way to the current modal
export const nextModalSet = createAsyncThunk("nextModalSet", async (newModalSettings: Modal, { getState }) => {
  let nextModal = {
    ...(getState() as RootState).view.nextModal,
    ...newModalSettings,
  }
  let query = nextModal.query;
  let asPath = nextModal.asPath;
  let updateLocation = false;
  if (nextModal.visible) {
    // A next modal should be lined up
    if (!asPath.includes(`${nextModal.type}-${nextModal.view}`)) {
      // It is not curently so add it to the url
      updateLocation = true;
      query.nextModal = `${nextModal.type}-${nextModal.view}`;
    }
  } else {
    if (asPath.includes(`nextModal=`)) {
      // There is a next modal in the url that should not be there, remove it
      updateLocation = true;
      if (query.nextModal) delete query.nextModal;
    }
  }

  if (updateLocation) {
    const newQuery = new URLSearchParams(query).toString()
    nextModal.router.push(
      `${window.location.pathname}?${newQuery}`
    )

    logModal({ type: nextModal.type, view: nextModal.view });
  }

  return nextModal;
});

// Slice
// ========================================
export const viewSlice = createSlice({
  name: "view",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(HYDRATE, (state, action) => {
        return {
          ...state,
        };
      })
      .addCase(modalSet.fulfilled, (state, action) => {
        state.modal = {
          ...state.modal,
          ...action.payload.modal,
        };
        state.nextModal = {
          ...state.nextModal,
          ...action.payload.nextModal,
        };
      })
      .addCase(nextModalSet.fulfilled, (state, action) => {
        state.nextModal = {
          ...state.nextModal,
          ...action.payload,
        };
      });
  },
});

export default viewSlice.reducer;
