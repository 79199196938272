import { AxiosResponse } from "axios";
import axios from "./axiosPrivate";
class PrivateApi {
  static async sendMail(user_id,data): Promise<AxiosResponse<any,any>> {
    return axios.post(
        `${process.env.NEXT_PUBLIC_CR_API_HOST}/oauth/user/${user_id}/mail`,
        data
    )
  }
  static async propertyContactEmail(data:any): Promise<AxiosResponse<any,any>> {
    return axios.post(
      `${process.env.NEXT_PUBLIC_CR_API_HOST}/oauth/enquiry`,
      {
        ...data
      }
    );
  }
  static async agentContactEmail(data:any): Promise<AxiosResponse<any,any>> {
    return axios.post(
      `${process.env.NEXT_PUBLIC_CR_API_HOST}/oauth/enquiry/agent`,
      {
        ...data
      }
    );
  }
}

export default PrivateApi;
