// ==================================================
// =====                PROPERTY                =====
// ==================================================
export const COMPARE = {
  fetch: "COMPARE_FETCH",
  load: "COMPARE_LOAD",
  update: "COMPARE_UPDATE",
  updateAll: "COMPARE_UPDATEALL",
  add: "COMPARE_ADD",
  clear: "COMPARE_CLEAR",
  clearAll: "COMPARAE_CLEARALL",
  show: "COMPARE_SHOW",
  remove: "COMPARE_REMOVE",
  emailSend: "COMPARE_EMAILSEND",
  emailSuccess: "COMPARE_EMAILSUCCESS",
};

// ==================================================
// =====                PROPERTY                =====
// ==================================================

export const PROPERTIES_COMPARE_LIST_ADD = "PROPERTIES_COMPARE_LIST_ADD";
export const PROPERTIES_COMPARE_LIST_REMOVE = "PROPERTIES_COMPARE_LIST_REMOVE";
export const PROPERTIES_COMPARE_LIST_CLEAR = "PROPERTIES_COMPARE_LIST_CLEAR";
export const PROPERTIES_COMPARE_SHOW = "PROPERTIES_COMPARE_SHOW";
export const PROPERTIES_COMPARE_EMAIL_SEND = "PROPERTIES_COMPARE_EMAIL_SEND";
export const PROPERTIES_COMPARE_EMAIL_SUCCESS =
  "PROPERTIES_COMPARE_EMAIL_SUCCESS";

// ==================================================
// =====                 SCHEME                 =====
// ==================================================
export const SCHEMES_SET_FEATURED = "SCHEMES_SET_FEATURED";

export const SCHEMES_COMPARE_LIST_ADD = "SCHEMES_COMPARE_LIST_ADD";
export const SCHEMES_COMPARE_LIST_REMOVE = "SCHEMES_COMPARE_LIST_REMOVE";
export const SCHEMES_COMPARE_LIST_CLEAR = "SCHEMES_COMPARE_LIST_CLEAR";
export const SCHEMES_COMPARE_SHOW = "SCHEMES_COMPARE_SHOW";
export const SCHEMES_COMPARE_EMAIL_SEND = "SCHEMES_COMPARE_EMAIL_SEND";
export const SCHEMES_COMPARE_EMAIL_SUCCESS = "SCHEMES_COMPARE_EMAIL_SUCCESS";

// ==================================================
// =====                  REPS                  =====
// ==================================================
export const REP_SEND_EMAIL = "REP_SEND_EMAIL";
export const REP_SEND_EMAIL_SUCCESS = "REP_SEND_EMAIL_SUCCESS";

// ==================================================
// =====                  AUTH                  =====
// ==================================================
export const AUTH = {
  login: {
    attempt: "AUTH_LOGIN",
    pending: "AUTH_LOGIN_PENDING",
    success: "AUTH_LOGIN_SUCCESS",
    fail: "AUTH_LOGIN_FAILED",
    redirect: "AUTH_LOGIN_REDIRECT",
  },
  resume: {
    attempt: "AUTH_RESUME",
    pending: "AUTH_RESUME_PENDING",
    success: "AUTH_RESUME_SUCCESS",
    fail: "AUTH_RESUME_FAILED",
  },
  refresh: {
    attempt: "AUTH_REFRESH",
    pending: "AUTH_REFRESH_PENDING",
    success: "AUTH_REFRESH_SUCCESS",
    fail: "AUTH_REFRESH_FAILED",
  },
  // cookie: {
  //   create: 'AUTH_COOKIE_CREATE',
  //   update: 'AUTH_COOKIE_UPDATE',
  //   destroy: 'AUTH_COOKIE_DESTROY',
  // },
  remember: "AUTH_REMEMBER_TOGGLE",
};
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_REMEMBER_TOGGLE = "AUTH_REMEMBER_TOGGLE";
export const AUTH_LOGIN_FAILED = "AUTH_LOGIN_FAILED";
export const AUTH_LOGIN_REDIRECT = "AUTH_LOGIN_REDIRECT";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_SET_TOKENS = "AUTH_SET_TOKENS";

export const AUTH_RESET_PASSWORD = "AUTH_RESET_PASSWORD";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_PENDING = "AUTH_RESET_PASSWORD_PENDING";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_CANCEL = "AUTH_RESET_PASSWORD_CANCEL";

export const AUTH_REGISTER = "AUTH_REGISTER";
export const AUTH_REGISTER_SUCCESS = "AUTH_REGISTER_SUCCESS";
export const AUTH_REGISTER_ERROR = "AUTH_REGISTER_ERROR";
export const AUTH_REGISTER_REQUEST = "AUTH_REGISTER_REQUEST";
export const AUTH_REGISTER_REQUEST_SUCCESS = "AUTH_REGISTER_REQUEST_SUCCESS";
export const AUTH_REGISTER_SET = "AUTH_REGISTER_SET";
export const AUTH_REGISTER_SET_SUCCESS = "AUTH_REGISTER_SET_SUCCESS";
export const AUTH_REGISTER_CANCEL = "AUTH_REGISTER_CANCEL";
export const AUTH_REGISTER_CANCEL_SUCCESS = "AUTH_REGISTER_CANCEL_SUCCESS";

// ==================================================
// =====                  USER                  =====
// ==================================================
export const USER_SET = "USER_SET";
export const USER_SET_DETAIL = "USER_SET_DETAIL";
export const USER_SET_AUTH = "USER_SET_AUTH";
export const USER_VERIFY = "USER_VERIFY";
export const USER_VERIFY_SUCCESS = "USER_VERIFY_SUCCESS";
export const USER_REMOVE = "USER_REMOVE";

// ==================================================
// =====                  VIEW                  =====
// ==================================================
export const VIEW_SET = "VIEW_SET";
export const VIEW_SET_MODE = "VIEW_SET_MODE";
export const VIEW_SET_PAGE = "VIEW_SET_PAGE";
export const VIEW_SET_TAB = "VIEW_SET_TAB";

export const MAP_TOGGLE_VISIBILITY = "MAP_TOGGLE_VISIBILITY";
export const MAP_HIGHLIGHT_MARKER = "MAP_HIGHLIGHT_MARKER";
export const MAP_CLEAR_MARKER = "MAP_CLEAR_MARKER";

export const PAUSE_VIDEO = "PAUSE_VIDEO";

export const SET_FILTERS_HEIGHT = "SET_FILTERS_HEIGHT";

// ==================================================
// =====              NOTIFICATIONS             =====
// ==================================================
export const NOTIFICATION_ADD = "NOTIFICATION_ADD";
export const NOTIFICATION_DISMISS = "NOTIFICATION_DISMISS";
export const NOTIFICATIONS_DISMISS_ALL = "NOTIFICATIONS_DISMISS_ALL";
export const NOTIFICATIONS_DISMISS_ALL_IN_LOCATION =
  "NOTIFICATIONS_DISMISS_ALL_IN_LOCATION";

// ==================================================
// =====                  BLOG                  =====
// ==================================================
export const BLOG_FETCH = "BLOG_FETCH";
export const BLOG_FETCH_SPECIFIC = "BLOG_FETCH_SPECIFIC";
export const BLOG_SET = "BLOG_SET";
export const BLOG_SET_SPECIFIC = "BLOG_SET_SPECIFIC";

// ==================================================
// =====                  THEME                 =====
// ==================================================
export const THEME_SET = "THEME_SET";
export const THEME_RESET = "THEME_RESET";
export const THEME_SET_PORTFOLIO = "THEME_SET_PORTFOLIO";
export const THEME_PORTFOLIO_CLEAR = "THEME_PORTFOLIO_CLEAR";
// export const THEME_ = 'THEME_';

// ==================================================
// =====                 COOKIES                =====
// ==================================================
export const COOKIE_CONSENT_STATE = "COOKIE_CONSENT_STATE";
export const COOKIE_UPDATE_STATE = "COOKIE_UPDATE_STATE";
export const COOKIE_AUTH_TOKEN = "CR_AUTH_TOKEN";
export const COOKIE_TOUR_STATE = "CR_TOUR_STATE";
export const COOKIE_AUTH_TOKEN_NEW = "cog_jwt"

// ==================================================
// =====                  OTHER                 =====
// ==================================================
export const GET_TRAVEL_TIMES = "GET_TRAVEL_TIMES";
export const SET_TRAVEL_TIMES = "SET_TRAVEL_TIMES";

export const NOTIFICATION_SEPARATOR = "____";
