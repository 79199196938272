export const colours = {
  textDark: [29, 37, 44],
  textLight: [238, 238, 238],

  gradient: {
    start: [232, 51, 107],
    end: [235, 94, 64],
  },
  primary: [236, 116, 116],

  white: [255, 255, 255],

  darkblack: [19, 27, 34],
  black: [29, 37, 44],
  midBlack: [35, 43, 50],
  lightBlack: [45, 53, 58],

  darkGrey: [45, 53, 58],
  lightGrey: [45, 53, 58],
  tan: [233, 228, 215],
  darkTan: [167, 144, 90],
  pink: [229, 30, 121],

  red: [235, 91, 86],

  gradientStart: {
    retail: [232, 51, 107], // e8336b
    flexible: [92, 191, 237], // 58BFED
  },
  gradientEnd: {
    retail: [235, 94, 64], // eb5e40
    flexible: [138, 206, 229], // 8ACEE5
  },
  gradAccent: {
    retail: [249, 197, 185],
    flexible: [207, 235, 245],
  },

  // gradientStart.retail: [232, 51, 107],
  // gradientEnd.retail: [235, 94, 64],

  // gradientStart.flexible: [92, 191, 237],
  // gradientEnd.flexible: [138, 206, 229],

  text: [34, 42, 48],
  link: [11, 121, 186],
  textInverse: [235, 235, 235],
  textStark: [0, 0, 0],
  siteBackground: [255, 255, 255],
  siteBackgroundSecondary: [250, 250, 250], // FAFAFA
  siteBackgroundTertiary: [242, 242, 242], // F2F2F2

  headerBackground: [29, 37, 44],
  headerText: [238, 238, 238],
  footerBackground: [29, 37, 44],
  footerText: [238, 238, 238],

  containerHeader: [251, 251, 251],
  containerBackgound: [255, 255, 255],
  containerBorder: [235, 235, 235],
  containerDivider: [235, 235, 235],

  sponsoredBackgound: [233, 228, 215],
  sponsoredBackgoundSecondary: [228, 223, 210],
  sponsoredBackgoundTertiary: [221, 216, 203],
  // sponsoredBackgoundHighlight: [221,216,203],
  sponsoredBorder: [167, 144, 90],

  unsponsoredBackgound: [255, 255, 255],
  unsponsoredBackgoundSecondary: [250, 250, 250],
  unsponsoredBackgoundTertiary: [242, 242, 242],
  // unsponsoredBackgoundHighlight: [242,242,242],
  unsponsoredBorder: [178, 178, 178],

  rowOdd: [242, 242, 242],
  rowEven: [194, 194, 194],

  tableRow_cyan: [227, 248, 247],
  tableRow_cyan1: [222, 243, 242],
  tableRow_cyan2: [215, 235, 234],
  tableRow_salmon: [255, 237, 219],
  tableRow_salmon1: [251, 232, 214],
  tableRow_salmon2: [243, 224, 207],
  tableRow_pink: [255, 236, 249],
  tableRow_pink1: [251, 231, 244],
  tableRow_pink2: [243, 223, 236],
  tableRow_grey: [228, 240, 237],
  tableRow_grey1: [223, 235, 232],
  tableRow_grey2: [216, 227, 224],
  tableRow_yellow: [255, 246, 220],
  tableRow_yellow1: [252, 241, 215],
  tableRow_yellow2: [244, 233, 208],

  home_signup: [87, 177, 172],

  dark: {
    text: [235, 235, 235],
    textInverse: [34, 42, 48],
    textStark: [255, 255, 255],
    siteBackground: [45, 53, 58],
    siteBackgroundSecondary: [40, 47, 52],
    siteBackgroundTertiary: [29, 37, 44],

    headerBackground: [29, 37, 44],
    headerText: [238, 238, 238],
    footerBackground: [29, 37, 44],
    footerText: [238, 238, 238],

    containerHeader: [19, 27, 34],
    containerBackgound: [35, 43, 50],
    containerDivider: [235, 235, 235],
    containerBorder: [9, 17, 24],

    sponsoredBackgound: [100, 86, 54],
    sponsoredBackgoundSecondary: [95, 80, 48],
    sponsoredBackgoundTertiary: [84, 70, 40],
    sponsoredBorder: [67, 58, 36],

    unsponsoredBackgound: [35, 43, 50],
    unsponsoredBackgoundSecondary: [30, 37, 44],
    unsponsoredBackgoundTertiary: [19, 47, 36],
    unsponsoredBorder: [9, 17, 24],

    rowOdd: [26, 33, 39],
    rowEven: [23, 29, 35],

    tableRow_cyan: [44, 175, 169],
    tableRow_cyan1: [39, 170, 164],
    tableRow_cyan2: [32, 162, 156],
    tableRow_salmon: [219, 110, 0],
    tableRow_salmon1: [215, 105, 0],
    tableRow_salmon2: [207, 97, 0],
    tableRow_pink: [235, 0, 160],
    tableRow_pink1: [231, 0, 155],
    tableRow_pink2: [223, 0, 147],
    tableRow_grey: [72, 132, 117],
    tableRow_grey1: [67, 127, 112],
    tableRow_grey2: [60, 119, 104],
    tableRow_yellow: [204, 153, 0],
    tableRow_yellow1: [201, 148, 0],
    tableRow_yellow2: [193, 140, 0],

    home_signup: [87, 177, 172],
  },
};

export const type = {
  base: 16,
  body: {
    size: 1,
    lineHeight: 1.1,
  },
};

export const font = {
  sizes:{
    
  },
  context:{
    title:'35.2px',
    subTitle:'22.6px',
    bodySmall:'14.4px',
    bodyMedium:'16px',
    bodyLarge:'18px'
  }
}
export const britishland = {
  base: 16,
  colors: {
    // blue: [53, 136, 175],
    blue: [53, 136, 175],
    lightBlue: [246, 249, 251],
    red:[194, 32, 44]
  },
};

export const property = {
  spacing:{
    sm:"1rem",
    md:"2rem",
      lg:"4rem"
  },
  table:{
    borderRadius:"15px"
  }
  
}

const _THEME = {
  colours,
  type,
  font,
  britishland,
  property,
};

export default _THEME;
