import axios, { AxiosInstance, AxiosResponse } from "axios";

const instancePrivate:AxiosInstance = axios.create();
instancePrivate.interceptors.response.use(function (response: AxiosResponse) {
    
    return response
},function (error:any){
    return error.response;
})

export default instancePrivate;
