import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

const instance: AxiosInstance = axios.create();
instance.interceptors.response.use(function (response: AxiosResponse) {
    
    return response
},function (error: any){
    return error.response;
})

instance.interceptors.request.use(function (request: AxiosRequestConfig) {
    request.headers.accept = "application/json";
    return request
},function (error:any){
    return error.response;
})

export default instance;
