import { createAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { statusIsGood } from "lib/middleware/errors";
import { RootState } from "lib/store";
import { HYDRATE } from "next-redux-wrapper";
import { ModalDetails, ModalState } from "_interfaces/modal";
const initialState:ModalState = {
  open: false,
  componentDetails: null,
  showClose:true,
  modalStack:[]
};

export const openModalAction = createAction<ModalDetails>('openModal')

export const closeModalAction = createAction<ModalDetails>('closeModal')

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action: any) => {
        return {
          ...state
        };
      })
      .addCase(openModalAction, (state, action: any) => {

        if(state.open){
          state.modalStack.push(state.componentDetails);
        }

        state.open = true;
        state.componentDetails = action.payload;
        state.showClose = 'showClose' in action.payload ? action.payload.showClose : true;
      })
      .addCase(closeModalAction, (state, action: any) => {

        state.open = false;
        state.componentDetails = null;
        state.showClose = initialState.showClose
        
        if(state.modalStack.length > 0){
          state.open = true;
          state.componentDetails = state.modalStack[0];
          state.modalStack.shift()
        }
      })
      
  },
});

export const isModalopen = (state:RootState):boolean => state.modal.open;
export const showModalCloseButton = (state:RootState):boolean => state.modal.showClose;
export const getComponentDetails = (state:RootState):ModalDetails => state.modal.componentDetails;

export default modalSlice.reducer;
