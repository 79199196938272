import { AxiosResponse } from "axios";
import axios from "./axios";
class PublicApi {
  static async login(username: string, password: string): Promise<AxiosResponse<any, any>> {
    return axios.post(`${process.env.NEXT_PUBLIC_CR_API_HOST}/oauth/oauth/token`, {
      client_id: process.env.NEXT_PUBLIC_CR_API_CLIENT_ID,
      client_secret: process.env.NEXT_PUBLIC_CR_API_CLIENT_SECRET,
      scope: process.env.NEXT_PUBLIC_CR_API_CLIENT_SCOPE,
      grant_type: "password",
      username,
      password,
    });
  }
  static async socialLogin(provider: string, token: string): Promise<AxiosResponse<any, any>> {
    return axios.post(`${process.env.NEXT_PUBLIC_CR_API_HOST}/oauth/oauth/token`, {
      client_id: process.env.NEXT_PUBLIC_CR_API_CLIENT_ID,
      client_secret: process.env.NEXT_PUBLIC_CR_API_CLIENT_SECRET,
      scope: process.env.NEXT_PUBLIC_CR_API_CLIENT_SCOPE,
      grant_type: "social",
      token,
      provider,
    });
  }
  static async refresh(refresh_token: string): Promise<AxiosResponse<any, any>> {
    return axios.post(`${process.env.NEXT_PUBLIC_CR_API_HOST}/oauth/oauth/token`, {
      client_id: process.env.NEXT_PUBLIC_CR_API_CLIENT_ID,
      client_secret: process.env.NEXT_PUBLIC_CR_API_CLIENT_SECRET,
      scope: process.env.NEXT_PUBLIC_CR_API_CLIENT_SCOPE,
      grant_type: "refresh_token",
      refresh_token,
    });
  }
  static async passwordResetRequest(email: string): Promise<AxiosResponse<any, any>> {
    return axios.post(`${process.env.NEXT_PUBLIC_CR_API_HOST}/public/user/resetpassword`, {
      email,
      // domain: process.env.NEXT_PUBLIC_APP_DOMAIN
      domain: "https://completelyretail.co.uk",
    });
  }
  static async passwordReset(invite: string, password: string): Promise<AxiosResponse<any, any>> {
    return axios.put(`${process.env.NEXT_PUBLIC_CR_API_HOST}/public/user/resetpassword`, {
      invite,
      password,
      // domain: process.env.NEXT_PUBLIC_APP_DOMAIN
      domain: "https://completelyretail.co.uk",
    });
  }
  static async registerRequest(name: string, email: string, captcha: string): Promise<AxiosResponse<any, any>> {
    return axios.post(`${process.env.NEXT_PUBLIC_CR_API_HOST}/public/user/register`, {
      name,
      email,
      captcha,
    });
  }
  static async register(
    request_id: any,
    forename: string,
    surname: string,
    password: string
  ): Promise<AxiosResponse<any, any>> {
    return axios.post(`${process.env.NEXT_PUBLIC_CR_API_HOST}/public/user/register/` + request_id, {
      forename,
      surname,
      password,
      domain: "https://completelyretail.co.uk",
    });
  }
  static async registerCancel(request_id: any): Promise<AxiosResponse<any, any>> {
    return axios.post(`${process.env.NEXT_PUBLIC_CR_API_HOST}/public/user/register/` + request_id + "?cancel=1&", {});
  }
  static async SendRepEmail(agent_id: number, data: any): Promise<AxiosResponse<any, any>> {
    return axios.post(`${process.env.NEXT_PUBLIC_APP_URL}/api/mail/agent`, {
      ...data,
      agent: agent_id,
      url: window.location.href,
    });
  }
  static async generalContactEmail(template: string, data: any, subject?: string): Promise<AxiosResponse<any, any>> {
    return axios.post(`${process.env.NEXT_PUBLIC_RENDER_API_HOST}mail/contactUsGeneral`, {
      subject,
      template,
      ...data,
    });
  }
  
}

export default PublicApi;
