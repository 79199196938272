import _get from "lodash/get";
import { StatusCodes } from "http-status-codes";

export const pageNotFound = (msg) => {
  return (req, res, next) => res.status(404).send(`404${msg ? `: ${msg}` : ""}`);
};

export const getStatusClass = (statusCode) => Math.floor(statusCode / 100);

export const statusIsGood = (statusCode) => {
  let status = getStatusClass(statusCode);
  return status === 2 || status === 3;
};
// import { statusIsGood } from 'lib/middleware/errors'
// statusIsGood(response.status)
// statusIsGood(status)

export const isResponseError = (statusCode) => {
  const statusClass = getStatusClass(statusCode);

  return statusClass === 4 || statusClass === 5;
};

export const processPageError = (errorLocation, error) => {
  const { status, statusText, data } = _get(error, "response", {});

  return {
    isError: true,
    status,
    statusText,
    data,
    errorLocation,
    stack: error.toJSON ? error.toJSON().stack : "",
  };
};

export const createStaticPropsError = (errorLocation, error) => {
  const { status, statusText, data } = _get(error, "response", {});

  return {
    isError: true,
    status,
    statusText,
    data,
    errorLocation,
    stack: error.toJSON ? error.toJSON().stack : "",
  };
};

export const staticPropsError = (locationInFile = 0, status = 500, error = null) => {
  console.error({
    status: status,
    neatStatus: StatusCodes[status],
    locationInFile: locationInFile,
    detail: null,
  });
  return {
    notFound: true,
  };
};
