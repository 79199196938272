import { createSlice, createAction } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
const initialState = {
  title: null,
  forename: "",
  middle: null,
  surname: "",
  suffix: null,
  email: "",
  avatar_id: null,
  avatar_uri: "",
  avatar_url: null,

  company: {
    id: 0,
    name: "",
  },

  id: null,
  type: "",
  status: "",
  registered: "",
  modified: "",
  last_login: "",
  n_logins: 0,
  trust: 10,
  error: null,
  message: "",
  sentMessages: [],
};

const prepareUserDetailState = (userDetail) => {
  let activeCompany;
  let { companies, telephone } = userDetail;
  let trustLevel = 10;
  let trustValues = [];

  if (userDetail.roles && Object.keys(userDetail.roles).length > 0) {
    Object.keys(userDetail.roles).map((role) => {
      trustValues.push(userDetail.roles[role].trust)
    })

    trustLevel = Math.max(...trustValues);
  }

  if(telephone && telephone.length > 0) {
    userDetail.telephone = telephone[0].number
  }

  userDetail.trust = trustLevel;
  // Check if a company exists
  if (companies && companies.length > 0) {
    // Check if there are multiple companies
    if (companies.length === 1) {
      activeCompany = companies[0];
    } else {
      // Check if there is a company with rank 0
      if (companies.filter((company) => company.rank === 0).length > 0)
        activeCompany = companies.filter((company) => company.rank === 0)[0];
      else {
        activeCompany = companies[0];
      }
    }

    userDetail.company = activeCompany;
    delete userDetail.employee;
  }

  return userDetail;
};

export const userRemove = createAction("userRemove");

export const setUserDetails = createAction("setUserDetails", function (payload) {
  return {
    payload: prepareUserDetailState(payload),
  };
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action) => {
        return {
          ...state,
        };
      })
      // .addCase(setUserDetails.fulfilled, (state, action) => {
      //   let newState = {
      //     ...current(state),
      //     ...action.payload,
      //     loggedIn: true,
      //   };

      //   return newState;
      // })
      .addCase(setUserDetails, (state, action) => {
        return {
          ...state,
          ...action.payload,
        };
      })
      .addCase(userRemove, (state, action) => {
        return initialState;
      });
  },
});

export const getUser = (state) => state.user;

export default userSlice.reducer;
