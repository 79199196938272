'use client';
import {  useReducer, createContext, useContext } from "react";
import { user, userIntialState } from "./reducers/user";
import { modal } from "./reducers/modal";

// initial state


// create context
const Context = createContext({});

// combine reducer function
const combineReducers = (...reducers) => (state, action) => {
  for (let i = 0; i < reducers.length; i++) state = reducers[i](state, action);
  return state;
};

// context provider
const Provider = ({ children, loggedIn }) => {
  const initialState = {
    user: userIntialState,
    modal: {
      id: null
    }
  };
  initialState.user.loggedIn = loggedIn;
  const [state, dispatch] = useReducer(combineReducers(user, modal), initialState); // pass more reducers combineReducers(user, blogs, products)
  const value = { state, dispatch };
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { Context, Provider };

export function checkLoggedIn() {
  return useContext(Context).state.user.loggedIn;
}

export function getTrustLevel() {
  return useContext(Context).state.user.trust;
}

export function getPayingClient() {
  return useContext(Context).state.user.company?.paying_client;
}

export function getUserDetails() {
  return useContext(Context).state.user;
}