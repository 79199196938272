import { NOTIFICATION_SEPARATOR } from "_constants";

import logException from "lib/ga/exception";

// ==================================================
// =======            EPIC FUNCTIONS           ======
// ==================================================

export const apiError = (error) => {
  return error;
};

// Takes an error and info from an epic, sends a GA event if required and then
// returns a notification action.
//
// @param   object                      The raw error from the
//          |-object        error       The raw error from the epic
//          |-string        location    Where the feedback will appear
//          |-string        state       The type of feedback
//          |-JSX           Feedback    The JSX that will be displayed as feedback
// @return  observable
export const handleError = ({ error, location, state, Feedback }) => {
  if (state === "error") {
    let date = new Date(Date.now());

    logException({
      description: `${error.name},${error.status},${error.message},${date.toISOString()}`,
      fatal: false,
    });
  }

  // return addNotification({
  //     location: location,
  //     state: state,
  //     Feedback: props => <Feedback />,
  // })
};

export const handleNotificationError = (name, status, message) => {
  let date = new Date(Date.now());
  logException({
    description: `${name},${status},${message},${date.toISOString()}`,
    fatal: false,
  });
};

// ==================================================
// =======          DISPLAY FUNCTIONS          ======
// ==================================================

// Destructures the id key to get the state
//
// @param   string          id          The id that we are going to deconstruct
// @return  string                      The id
export const getStateFromId = (id) => id.split(NOTIFICATION_SEPARATOR)[0];

// Destructures the id key to get the location
//
// @param   string          id          The id that we are going to deconstruct
// @return  string                      The location
export const getLocationFromId = (id) => id.split(NOTIFICATION_SEPARATOR)[1];

// Destructures the id key to get the timestamp
//
// @param   string          id          The id that we are going to deconstruct
// @return  string                      The timestamp
export const getTimestampFromId = (id) => id.split(NOTIFICATION_SEPARATOR)[2];

// Filter notifications and return those that are in the desired location
//
// @param   string          location    The location we want to retrieve
// @param   object          notes       All the notes that we want to filter
// @return  array                       The notes in the location
export const getNotesInLocation = (location, notes) =>
  Object.keys(notes).filter((note) => getLocationFromId(note) === location);

// Find out which states and how many of them are in an group of notes
//
// @param   array           notes       All the notes that we want to check
// @return  object                      The states in [state]:[amount] pairs
export const retrieveStatesFromNotes = (notes) => {
  if (notes.length <= 0) return false;
  let states = {};
  let state = "";
  notes.forEach((id) => {
    state = getStateFromId(id);
    if (state in states) states[state] = states[state] + 1;
    else states[state] = 1;
  });
  return states;
};
