import { statusIsGood } from "lib/middleware/errors";

export function user(state, action) {
  switch (action.type) {
    case "LOGGED_IN_USER":
      let userData = prepareUserDetailState(action.payload)
      userData.loggedIn = true;
      return { ...state, user: userData };
    case "LOG_OUT_USER":
      const data = { ...state, user: userIntialState }
      data.user.loggedIn = false;
      return data;
    default:
      return state;
  }
};

export const userIntialState = {
  title: null,
  forename: "",
  middle: null,
  surname: "",
  suffix: null,
  email: "",
  avatar_id: null,
  avatar_uri: "",
  avatar_url: null,
  company: {
    id: 0,
    name: "",
  },
  id: null,
  type: "",
  status: "",
  registered: "",
  modified: "",
  last_login: "",
  n_logins: 0,
  trust: 10,
  error: null,
  message: "",
  sentMessages: [],
  loggedIn: false
};

export const processTrustLevels = (roles) => {
  let trustLevel = 10;
  let trustValues = [];
  if (roles && Object.keys(roles).length > 0) {
    Object.keys(roles).map((role) => {
      trustValues.push(roles[role].trust)
    })

    trustLevel = Math.max(...trustValues);
  }
  return trustLevel;
}

export const processTelephone = (telephone) => {
  if (telephone && telephone.length > 0) {
    return telephone[0].number;
  }
  return null;
}

export const processCompanies = (companies) => {
  let activeCompany;

  if (companies && companies.length > 0) {
    // Check if there are multiple companies
    if (companies.length === 1) {
      activeCompany = companies[0];
    } else {
      // Check if there is a company with rank 0
      if (companies.filter((company) => company.rank === 0).length > 0)
        activeCompany = companies.filter((company) => company.rank === 0)[0];
      else {
        activeCompany = companies[0];
      }
    }
    return activeCompany;
  }

  return null;
}

export const prepareUserDetailState = (userDetail) => {
  let { companies } = userDetail;

  userDetail.telephone = processTelephone(userDetail.telephone);
  userDetail.trust = processTrustLevels(userDetail.roles);
  userDetail.company = processCompanies(companies);

  return userDetail;
}

export const userLogOut = async (dispatch) => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_CR_JS_API}auth/logout`, {
    method: 'POST',
    headers: new Headers({ 'content-type': 'application/json' }),
    credentials: "include"
  })
  response.json().then(data => {
    if (statusIsGood(response.status)) {
      if (!data.loggedIn) {
        dispatch({
          type: "LOG_OUT_USER"
        });
        return data;
      }
    }
  })
}